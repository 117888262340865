$(document).ready(function () {
  // add smooth scroll to html
  $('html').css('scroll-behavior', 'smooth')

  if ($('.accordion-examination').length === 0) return

  // setup status-links (bullets) based on currentStep attribute value
  const currentStep = $('.status-links').attr('currentStep')

  // open target accordion after status link click
  $('.status-links ul li a , .status-links .link a').on('click', function () {
    // close all accordions
    $('.accordion-examination').removeClass('active')
    $('.accordion-examination .content').hide()

    // open target accordion
    const accordionID = $(this).attr('href')
    $(accordionID).addClass('active')
    $(accordionID).find('.content').delay(700).slideToggle('fast')
  })

  // Accordion control
  $('.accordion-examination').each(function (index) {
    const accordion = $(this)
    const accordionHeader = accordion.find('.accordion-header')
    const content = accordion.find('.content')

    accordionHeader.on('click', function () {
      accordion.toggleClass('active')
      $(this).attr('aria-expanded', $(this).attr('aria-expanded') === 'true' ? 'false' : 'true')
      content.slideToggle('fast')
    })
  })

  const hash = window.location.hash

  if (hash) {
    $(`.accordion-examination${hash}`).find('.accordion-header').get(0).click()
  } else {
    $('.accordion-examination').find('.accordion-header').get(0).click()
  }
})
