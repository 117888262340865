function filterDropdown () {
    if (!document.querySelector('.filter-header')) return

    const filterDropdown = document.querySelector('.filter-header__dropdown')

    if (!filterDropdown) return

    const filterBtn = filterDropdown.querySelector('.btn')

    if (!filterBtn) return

    filterBtn.addEventListener('click', () => {
        filterDropdown.classList.toggle('open')
        if (filterDropdown.classList.contains('open')) {
            filterDropdown.setAttribute('aria-expanded', 'true')
        } else {
            filterDropdown.setAttribute('aria-expanded', 'false')
        }
    })

    // Close when click outside
    document.addEventListener('click', function (e) {
        const target = e.target

        if (!target) return

        if (!filterDropdown.contains(target)) {
            filterDropdown.classList.remove('open')
            filterDropdown.setAttribute('aria-expanded', 'false')
        }
    })
}

document.addEventListener('DOMContentLoaded', function () {
    filterDropdown()
})
