function exportPdf (serviceUrl, title) {
  $('#downloadPdfLoader').css('display', 'flex')

  $.ajax({
    type: 'POST',
    url: serviceUrl,
    dataType: 'json',
    processData: true,
    data: {
      url: `${location.protocol}//${location.host}${location.pathname}`
    },
    success: function (data) {
      if (!data.content) {
        $('#downloadPdfLoader').hide()
        return
      }

      const a = document.createElement('a')
      // a.href = 'data:application/pdf;base64,' + data.content

      // data should be your response data in base64 format
      const blob = dataURItoBlob(data.content);
      const url = URL.createObjectURL(blob);
      a.href = url

      // to open the PDF in a new window
      // window.open(url, '_blank');


      a.download = `${title}.pdf`
      a.click()

      $('#downloadPdfLoader').hide()
    },
    error: function () {
      $('#downloadPdfLoader').hide()
      console.error('Error downloading pdf !')
    }
  })
}


function dataURItoBlob (dataURI) {
  const byteString = window.atob(dataURI);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'application/pdf' });
  return blob;
}
