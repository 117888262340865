document.addEventListener('DOMContentLoaded', function () {
    setTimeout(() => {
        accordionUser()
    }, 500)
})

function accordionUser () {
    if (!document.querySelector('.accordion-user')) return

    const accordionBtn = document.querySelectorAll('.accordion-user__item > .btn') || []

    accordionBtn.forEach(btn => {
        btn.addEventListener('click', accordionEvent)
    })
}

function accordionEvent (e) {
    const accordionItem = e.target.parentNode
    const accordionBody = accordionItem.querySelector('.accordion-body')
    const accordionContent = accordionItem.querySelector('.accordion-body > .accordion-content')

    if (!accordionItem || !accordionBody || !accordionContent) return

    const contentHeight = accordionContent.getBoundingClientRect().height

    function closeItem () {
        accordionItem.classList.remove('open')
        accordionItem.setAttribute('aria-expanded', 'false')
        accordionBody.style.height = '0px'

        setTimeout(() => {
            accordionContent.style.visibility = 'hidden'
        }, 500)
    }

    function openItem () {
        accordionContent.style.visibility = 'visible'
        accordionItem.classList.add('open')
        accordionItem.setAttribute('aria-expanded', 'true')
        accordionBody.style.height = `${contentHeight}px`
    }

    if (!accordionItem.classList.contains('open')) {
        openItem()
    } else {
        closeItem()
    }
}
