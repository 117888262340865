function getAllParamsFromUrl () {
  const query = location.search.substr(1)
  const params = query.split('&')
  const result = {}

  for (let i = 0; i < params.length; i++) {
    const item = params[i].split('=')

    const key = item[0].replace(/\[|\]/g, '')
    const value = item[1]

    if (!result[key]) result[key] = [value]
    else result[key].push(value)
  }

  return result
}

function setParametersOnURL (params) {
  let urlWithParams = location.origin + location.pathname

  if (Object.keys(params).length) {
    const currentParams = getAllParamsFromUrl()
    params = Object.assign(currentParams, params)
  }

  if (params) {
    const parametersOnURL = Object.keys(params)
      .filter((parameterName) => parameterName && params[parameterName])
      .filter((parameterName) =>
        Array.isArray(params[parameterName])
          ? params[parameterName].length > 0
          : true
      )
      .map(
        (parameterName) =>
          `${$.param({ [parameterName]: params[parameterName] }, true)}`
      )
      .join('&')

    if (parametersOnURL) {
      urlWithParams += decodeURI(`?${parametersOnURL}`)
    }
  }

  history.pushState(undefined, undefined, urlWithParams)
}

function getStringQueryParams (params) {
  let strParams = '?'

  Object.keys(params).forEach((key) => {
    if (!key || !params[key]) return true

    if (Array.isArray(params[key])) {
      params[key].forEach((val) => {
        strParams += `${key}=${val}&`
      })
    } else {
      strParams += `${key}=${params[key]}&`
    }
  })

  return strParams
}
