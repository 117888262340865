$(document).ready(function () {
  const chapterSideNav = $('.chapter-links__sticky .chapter-links')

  // Open chapter side nav
  $('.chapter-links__sticky .open-btn').on('click', function () {
    chapterSideNav.toggleClass('active')
    $(this).attr('aria-expanded', $(this).attr('aria-expanded') === 'true' ? 'false' : 'true')

    if ($(window).width() <= 600) {
      $(this).find('.icon-mobile').toggleClass('icon--list-menu').toggleClass('icon--menu-close')
    }
  })

  // Close chapter side nav
  $('.chapter-links__sticky .icon--menu-close').on('click', function () {
    chapterSideNav.removeClass('active')
    $('.chapter-links__sticky .open-btn').attr('aria-expanded', false)
  })
})
