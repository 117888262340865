$(document).ready(function () {
	$('.readmore-link').each(function () {
		$(this).click(function (e) {
			// record if our text is expanded
			const isExpanded = $(e.target).hasClass('expand')

			// close all open paragraphs
			$('.fact-box.expand').removeClass('expand')
			$('.readmore-link.expand').removeClass('expand').attr('aria-expanded', false)

			// if target wasn't expand, then expand it
			if (!isExpanded) {
				$(e.target).parent('.fact-box').addClass('expand')
				$(e.target).addClass('expand').attr('aria-expanded', true)
			}
		})
	})
})
