$(document).ready(function () {
	const maxLength = 132

	$('.show-read-more').each(function () {

		if($('.show-read-more').hasClass("employees description")) {
			return
		}

		const myStr = $(this).text()

		if ($.trim(myStr).length > maxLength) {
			const newStr = myStr.substring(0, maxLength)
			const removedStr = myStr.substring(maxLength, $.trim(myStr).length)

			const link = $('[name=input_employee_link]').val() || ''
			const readMore = $('[name=input_employee_readMore]').val() || 'Les mer'

			$(this).empty().html(newStr)

			if(link && link.length) {
				$(this).append(`<a href="${link}" class="read-more">${readMore}</a>`)
			}

			$(this).append('<span class="more-text">' + removedStr + '</span>')
		}
	})

	$('.show-read-more-expand').each(function () {
		const myStr = $(this).text()
		if ($.trim(myStr).length > maxLength) {
			const newStr = myStr.substring(0, maxLength)
			const removedStr = myStr.substring(maxLength, myStr.length)

			const link = $('[name=input_employee_link]').val() || ''
			const readMore = $('[name=input_employee_readMore]').val() || 'Les mer'
			const readLess = $('[name=input_employee_readLess]').val() || 'Lukk'

			$(this).empty().html(newStr)
			$(this).append(`<a href="#" class="read-more">${readMore}</a>`)
			$(this).append(`<span class="more-text">${removedStr}</span><br>`)
			$(this).append(`<a href="#" class="read-less">${readLess}</a>`)
		}
		$('.read-more').on('click', function (event) {
			event.preventDefault()
			const readMoreButton = $(this)
			const descriptionText = $(this).parent()
			const moreText = descriptionText.find('.more-text')
			const readLessButton = descriptionText.find('.read-less')

			moreText.slideDown()
			readMoreButton.hide()
			readLessButton.show()
		})

		$('.read-less').on('click', function (event) {
			event.preventDefault()
			const descriptionText = $(this).parent()
			const readLessButton = $(this)
			const readMoreButton = descriptionText.find('.read-more')
			const moreText = descriptionText.find('.more-text')

			moreText.slideUp()
			readLessButton.hide()
			readMoreButton.show()
		})
	})
})
