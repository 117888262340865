$(document).ready(function () {
	$('.htmlArea-article-content table').each(function () {
		const table = $(this)
		const thead = table.find('thead')
		const th = thead.find('th')
		const td = table.find('td')

		table.basictable({
			breakpoint: 800
		})

		// Remove &nbsp character from empty "th"
		$(th).html(function (i, html) {
			return html.replace(/&nbsp;/g, '')
		})

		// add class "empty-th" to any empty th
		th.each(function () {
			if ($(this).is(':empty')) {
				$(this).addClass('empty-th')
			}
		})

		// check if all <th> are empty and add class "no-header" to table
		if (thead.find('.empty-th').length == th.length) {
			table.addClass('no-header')
		}

		// check if the table has "thead" and add class "no-header" to table
		if (!thead.length) {
			table.addClass('no-header')
		}

		td.each(function (i, currentTd) {
			const dataTh = currentTd.attributes &&
				currentTd.attributes['data-th'] &&
				currentTd.attributes['data-th'].value

			if (dataTh && (dataTh.trim() === '')) {
				currentTd.classList.add('empty-td')
			}
		})
	})
})
