$(document).ready(function () {
  $('.report-links__links ul li button').on('click', function () {
    const parentListItem = $(this).parent()
    const dropdownContent = parentListItem.find('.dropdown-content')

    parentListItem.toggleClass('active')
    $(this).attr('aria-expanded', $(this).attr('aria-expanded') === 'true' ? 'false' : 'true')

    // Close oppened dropdowns before open another
    $('.dropdown-content').not(dropdownContent).slideUp()
    $('.report-links__links ul li').not(parentListItem).removeClass('active')
    $('.report-links__links ul li').not(parentListItem).find('button').attr('aria-expanded', false)

    // Open dropdown
    parentListItem.find('.dropdown-content').slideToggle()

    // Show / Hide read more link
    if ($('.report-links__links ul li').hasClass('active')) {
      $('.report-links .read-more-link').css('display', 'table')
    } else {
      $('.report-links .read-more-link').css('display', 'none')
    }
  })
})
