$(document).ready(function () {
  // Open search-bar (window width > 992px)
  $('.main-header .search-btn').on('click', function () {
    if ($(window).width() > 992) {
      $(this).attr('aria-expanded', $(this).attr('aria-expanded') === 'true' ? 'false' : 'true')
      $('.main-header .search-bar').toggleClass('hide')
      $('.main-header .search-btn').find('i').toggleClass('icon--menu-close-light')

      // Toggle button text
      if ($('.main-header .search-bar').hasClass('hide')) {
        $('.main-header .search-btn')
          .find('span')
          .text('Søk')
          .attr('style', '')
      } else {
        $('.main-header .search-btn')
          .find('span')
          .text('Lukk søk')
      }

      // Toggle breadcrumbs style
      $('.breadcrumbs__wrapper').toggleClass('search-active')
    }
  })

  // Open menu (mobile)
  $('.main-header .open-menu-btn').on('click', function () {
    if ($(window).width() <= 992) {
      $(this).attr('aria-expanded', $(this).attr('aria-expanded') === 'true' ? 'false' : 'true')
      $('.menu-mobile').toggleClass('hide')
      $('.main-header .open-menu-btn')
        .find('i')
        .toggleClass('icon--close-blue')

      // Toggle button text
      if ($('.main-header .menu-mobile').hasClass('hide')) {
        $('.main-header .open-menu-btn').find('span').text('Meny')
      } else {
        $('.main-header .open-menu-btn').find('span').text('Lukk')
      }
    }
  })

  $('div.header__wrapper').delegate(
    'button.header-searchtext-btn',
    'click',
    handleHeaderSearch
  )

  $('div.page-404').delegate(
    'button.pageNotFoundSearchBtn',
    'click',
    handleHeaderSearch
  )

  $('.header__wrapper .search-bar input').on('keypress', handleEnterSearch)
})

function handleEnterSearch (e) {
  const btn = $('.search-bar .header-searchtext-btn')

  if (e.keyCode === 13) {
    e.preventDefault()
    const url = btn.data('searchpageurl')
    const searchText = btn
      .siblings(
        'input[name=header-searchtext-input], input[name=pageNotFoundSearchInput]'
      )
      .val()

    location.href = searchText ? `${url}?searchText=${searchText}` : url
  }
}

function handleHeaderSearch (e) {
  e.preventDefault()
  const btn = $(this)
  const url = btn.data('searchpageurl')
  const searchText = btn
    .siblings(
      'input[name=header-searchtext-input], input[name=pageNotFoundSearchInput]'
    )
    .val()

  location.href = searchText ? `${url}?searchText=${searchText}` : url
}
